import { createSlice } from "@reduxjs/toolkit"

export const brandSlice = createSlice({
  name: "brand",
  initialState: {
    sneakerBrandList: [],
    apparelBrandList: [],
    addonBrandList: [],
    brandList: [],
    exportList: [],
    brandDetail: {},
    isFreshData: false,
    sneakerTotalEntry: 0,
    apparelTotalEntry: 0,
    addonTotalEntry: 0,
    totalEntry: 0,
    filterTerm: ''
  },
  reducers: {
    setSneakerBrandList: (state, action) => {
      state.sneakerBrandList = action.payload
    },
    setApparelBrandList: (state, action) => {
      state.apparelBrandList = action.payload
    },
    setAddonBrandList: (state, action) => {
      state.addonBrandList = action.payload
    },
    setBrandList: (state, action) => {
      state.brandList = action.payload
    },
    setExportList: (state, action) => {
      state.exportList = action.payload
    },
    setFilterTerm: (state, action) => {
      state.filterTerm = action.payload
    },
    setFreshList: (state, action) => {
      state.isFreshData = action.payload
    },
    setSneakerTotalEntry: (state, action) => {
      state.sneakerTotalEntry = action.payload
    },
    setApparelTotalEntry: (state, action) => {
      state.apparelTotalEntry = action.payload
    },
    setAddonTotalEntry: (state, action) => {
      state.addonTotalEntry = action.payload
    },
    setTotalEntry: (state, action) => {
      state.totalEntry = action.payload
    },
    setSingleData: (state, action) => {
      state.brandDetail = action.payload
    }
  }
})

export const {
  setSneakerBrandList,
  setApparelBrandList,
  setAddonBrandList,
  setBrandList,
  setExportList,
  setFreshList,
  setFilterTerm,
  setSingleData,
  setSneakerTotalEntry,
  setApparelTotalEntry,
  setAddonTotalEntry,
  setTotalEntry,
  setFilteredList
} = brandSlice.actions

export const selectBrandList = (state) => state.brand.brandList
export const selectExportList = (state) => state.brand.exportList
export const selectFilterTerm = (state) => state.brand.filterTerm
export const selectSneakerTotalEntry = (state) => state.size.sneakerTotalEntry
export const selectApparelTotalEntry = (state) => state.size.apparelTotalEntry
export const selectAddonTotalEntry = (state) => state.size.addonTotalEntry
export const selectTotalEntry = (state) => state.brand.totalEntry
export const selectFreshData = (state) => state.brand.isFreshData
export const selectBrandDetail = (state) => state.brand.brandDetail

export default brandSlice.reducer
